import { StyleSheet, css } from "aphrodite";
import Link from "next/link";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useExperiment } from "../contexts/ExperimentContext";

const Legalese = forwardRef(
  ({ buttonTextNames, contactType, className, otherProviders }, ref) => {
    const { t } = useTranslation(["translation", "common"]);
    const { experiment } = useExperiment();

    if (experiment.includes("new_legalese")) {
      return (
        <div
          className={`${css(aphStyles.legalese)} ${className ? className : ""}`}
          ref={ref}
          id="legalese"
        >
          By clicking &quot;
          {buttonTextNames ? buttonTextNames : t("accept")}
          &quot;, you agree to receive marketing calls/texts regarding your
          inquiry from or on behalf of ReferralExchange and up to 8 of our
          participating agents at the phone number above, including calls/texts
          via an automated system. Freq. varies. Consent is not a condition of
          any purchase or access to our services. You may revoke this any time.
        </div>
      );
    }

    return (
      <div
        className={`${css(aphStyles.legalese)} ${className ? className : ""}`}
        ref={ref}
        id="legalese"
      >
        By clicking &quot;
        {buttonTextNames ? buttonTextNames : t("accept")}
        &quot;, you consent, acknowledge and agree to our{" "}
        {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
        <a className={css(aphStyles.link)} href="/tos" target="_blank">
          Terms of Use
        </a>{" "}
        and {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
        <a className={css(aphStyles.link)} href="/privacy" target="_blank">
          Privacy Policy
        </a>{" "}
        which includes binding arbitration and consent to receive electronic
        communications, you are providing express written consent for
        RealEstateAgents.com and our participating agents{" "}
        {otherProviders && otherProviders} to contact you at the phone number
        you provided via calls and/or SMS, MMS using an automated dialing system
        to provide agent info. Even if your number is listed on an internal,
        corporate, state or federal Do-Not-Call list. You may revoke this
        consent any time. Consent is not a condition for our service.
      </div>
    );
  }
);

Legalese.displayName = "Legalese";
export default Legalese;

const aphStyles = StyleSheet.create({
  legalese: {
    fontSize: 13,
    // opacity: 0.5,
    letterSpacing: 0.2,
    lineHeight: 1.4,
    color: "#585F69",
    // color: "rgb(39, 39, 39)",

    "@media only screen and (max-height: 700px)": {
      fontSize: 12,
    },

    "@media only screen and (min-width: 1024px)": {
      fontSize: 16,
    },
  },
  oldLegalese: {
    fontSize: 12,
    opacity: 0.4,
    "@media only screen and (min-width: 1024px)": {
      fontSize: 12,
    },
  },
  link: {
    textDecoration: "underline",
    color: "#0000EE",
  },
});
